<template>
  <div class="home page">
    <OHeroStatic />

    <div class="section">
      <MSectionHeader
        :title="$t('Serviciile Noastre')"
        :subtitle="true"
        :description="$t(services_description)"
      />
      <OServices :services="services" />
    </div>

    <div class="section">
      <MSectionHeader :title="$t('Cum Funcționează?')" />
      <MSteps :steps="steps" />
    </div>
    <!-- <div class="section">
      <MSectionHeader
        :title="$t('Echipa Noastră')"
        :description="team_description"
      />
      <div class="team">
        <MMemberCard v-for="(member, i) in members" :key="i" :member="member" />
      </div>
    </div> -->
  </div>
</template>

<script>
// @ is an alias to /src
import MSectionHeader from "@/components/molecules/m-section-header.vue";
import MSteps from "@/components/molecules/m-steps.vue";
// import MMemberCard from "@/components/molecules/m-member-card.vue";
import OHeroStatic from "@/components/organisms/o-hero-static.vue";
import OServices from "@/components/organisms/o-services.vue";

export default {
  name: "Home",
  components: {
    MSectionHeader,
    MSteps,
    // MMemberCard,
    OHeroStatic,
    OServices,
  },
  data() {
    return {
      services_description:
        "Un birou  de consultanță financiară la curent cu cele mai noi tendințe, care servește drept motor pentru obținerea de informații , suport dedicat și o raportare clară și precisă, facilitându-vă parcursul spre realizarea și chiar depășirea targeturilor financiare.",
      team_description:
        "A modern CPA firm that is your catalyst to actionable insights, dedicated support, and transparent reporting so you can meet and exceed your financial goals.",
      steps: [
        {
          title: "1. Analizăm",
          description:
            "Efectuăm o analiză financiară care evaluează performanța economică și stabilește strategii pentru viitor.",
          image: require("@/assets/icons/consultation.png"),
        },
        {
          title: "2. Soluții",
          description:
            "Elaborăm soluții strategice și personalizate strict pe dorințele și nevoile dumneavoastră.",
          image: require("@/assets/icons/task.png"),
        },
        {
          title: "3. Parteneriat",
          description:
            "Parteneriatul nostru transformă soluțiile în rezultate concrete, îmbunătățind perspectiva financiară pe termen lung.",
          image: require("@/assets/icons/deal.png"),
        },
        // {
        //   title: '4. System Design & Automation',
        //   description: 'You can customise your insurance policy and choose to safeguard exactly what you want.',
        //   image: require('@/assets/icons/family.png')
        // }
      ],
      services: [
        {
          name: "Asigurări Private",
          children: [
            {
              title: "Prevenție",
              description:
                "Prin consultanța noastră oferim prevenție financiară în Germania care se referă la strategii și servicii care ajută la prevenirea problemelor financiare.",
              icon: require("@/assets/icons/health-care.png"),
              link: `/${this.$i18n.locale}/insurances#personal-insurance`,
            },
            {
              title: "Sănătate",
              description:
                "Prin asigurările de sănătate care sunt proiectate pentru a susține costurile tratamentelor medicale, minimizând astfel impactul financiar al problemelor de sănătate și asigurând accesul la îngrijiri necesare.",
              icon: require("@/assets/icons/shield.png"),
              link: `/${this.$i18n.locale}/insurances#personal-insurance`,
            },
            {
              title: "Protecție",
              description:
                "Prin asigurările de protecție care  oferă suport financiar pentru a acoperi riscuri specifice precum accidente, boală, sau pierderea capacității de muncă, garantând astfel securitatea financiară în fața evenimentelor neprevăzute.",
              icon: require("@/assets/icons/alimony.png"),
              link: `/${this.$i18n.locale}/insurances#personal-insurance`,
            },
            {
              title: "Pensie",
              description:
                "Prin asigurările de pensie cu planuri de economii pe termen lung care asigură un venit stabil după retragerea din viața activă, contribuind la securitatea financiară în timpul pensionării.",
              icon: require("@/assets/icons/leisure.png"),
              link: `/${this.$i18n.locale}/insurances#personal-insurance`,
            },
          ],
          see_more: false,
        },
        {
          name: "Asigurări Firme",
          content: {
            title: "Asigurări Firme",
            description:
              "Asigurările pentru firme oferă protecție împotriva riscurilor specifice activității comerciale, asigurând stabilitatea și continuitatea afacerii în fața evenimentelor neprevăzute. Acestea includ acoperiri esențiale pentru daune materiale, cum ar fi clădiri, echipamente și stocuri, protejând împotriva pierderilor cauzate de incendii, inundații, furturi sau alte dezastre. De asemenea, asigurările de răspundere civilă protejează firma împotriva revendicărilor de despăgubire din partea terților pentru daune aduse proprietății sau sănătății acestora, acoperind costurile legale și despăgubirile financiare. În plus, asigurarea pentru întreruperea afacerii oferă suport financiar în cazul suspendării temporare a activității, acoperind pierderile de venituri și costurile operaționale suplimentare. Firma noastră de consultanță financiară vă oferă soluții personalizate pentru a identifica și implementa cele mai potrivite polițe de asigurare, asigurând protecția și succesul pe termen lung al afacerii dumneavoastră.",
            link: `/${this.$i18n.locale}/insurances#business-insurance`,
          },
        },
        {
          name: "Credite",
          children: [
            {
              title: "Credit Nevoi Personale",
              description:
                "Prin creditul de nevoi personale o formă de împrumut pentru finanțarea cheltuielilor personale, fără a specifica destinația fondurilor.",
              icon: require("@/assets/icons/loan.png"),
              link: `/${this.$i18n.locale}/loans`,
            },
            {
              title: "Credite Ipotecare",
              description:
                "Prin creditul ipotecar o formă de finanțare pentru achiziționarea unui imobil, oferind un împrumut pe termen lung garantat cu proprietatea achiziționată",
              icon: require("@/assets/icons/refinancing.png"),
              link: `/${this.$i18n.locale}/loans`,
            },
            {
              title: "Refinanțări",
              description:
                "Refinanțarea este o formă de înlocuire a unui credit existent cu unul nou, adesea pentru a beneficia de o dobândă mai avantajoasă sau de condiții mai bune de rambursare.",
              icon: require("@/assets/icons/car-loan.png"),
              link: `/${this.$i18n.locale}/loans`,
            },
            {
              title: "Deschideri Conturi Gratuite",
              description:
                "Deschiderea de conturi gratuite se referă la procesul de a crea un nou cont bancar fără a suporta costuri de administrare sau alte comisioane lunare *timp de 2 ani.",
              icon: require("@/assets/icons/vacation.png"),
              link: `/${this.$i18n.locale}/loans`,
            },
          ],
          see_more: false,
        },
        {
          name: "Dezvoltare Financiară",
          content: {
            title: "Dezvoltare Financiară",
            description:
              "Dezvoltarea financiară se referă la procesul de îmbunătățire a stabilității și eficienței sistemului financiar prin inovații, acces mai larg la servicii financiare și creșterea piețelor de capital. Aceasta implică adoptarea de noi tehnologii și practici care optimizează funcționarea instituțiilor financiare și facilitează accesul pentru diverse segmente ale populației și afacerilor. De asemenea, dezvoltarea piețelor de capital joacă un rol crucial în asigurarea resurselor necesare pentru investiții și creștere economică. Firma noastră de consultanță financiară vă ajută să navigați acest proces complex, oferindu-vă expertiză și soluții personalizate pentru a beneficia de oportunitățile oferite de dezvoltarea financiară, asigurând astfel stabilitatea și creșterea sustenabilă a afacerii dumneavoastră.",
            link: `/${this.$i18n.locale}/investments`,
          },
        },
        {
          name: "Carieră",
          content: {
            title: "Carieră",
            description:
              "Oferim sansa unui ausbildung pentru o carieră în finanțe cu oportunități dinamice și diverse, de la analiză financiară și consultanță de investiții, până la banking și gestionarea riscurilor. Este ideală pentru cei pasionați de cifre și tendințe economice, oferind șansa de a influența deciziile financiare la nivel individual și corporativ, și de a contribui la creșterea economică sustenabilă.",
          },
        },
      ],
      members: [
        {
          name: "John",
          role: "Founder and CEO",
          description:
            "Lorem Ipsum is a simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Industry's standard dummy text ever since the 1500s.",
          image: require("@/assets/team/john.jpg"),
        },
        {
          name: "Milan",
          role: "Lead Developer",
          description:
            "Lorem Ipsum is a simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Industry's standard dummy text ever since the 1500s.",
          image: require("@/assets/team/milan.jpg"),
        },
        {
          name: "Smith",
          role: "Marketing Executive",
          description:
            "Lorem Ipsum is a simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Industry's standard dummy text ever since the 1500s.",
          image: require("@/assets/team/smith.jpg"),
        },
        {
          name: "Natasha",
          role: "Senior Designer",
          description:
            "Lorem Ipsum is a simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the Industry's standard dummy text ever since the 1500s.",
          image: require("@/assets/team/natasha.jpg"),
        },
      ],
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.section {
  max-width: 1272px;
  margin: 0 auto;
  text-align: center;
  padding: var(--spacer-2xl) var(--spacer-xl);
  border-bottom: 1px solid var(--c-light);
  @include for-mobile {
    padding: var(--spacer-xl) var(--spacer-lg);
  }
}

.team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--spacer-2xl);
}
</style>
